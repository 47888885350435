import React, { useState, Fragment, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { defaultToEmptyString, defaultToZero, handleEvent } from "gx-npm-lib";
import { BaseTextArea, Paragraph } from "gx-npm-ui";
import styles from "./response-option-and-comment-row.styles";
import { MessageIconWithToolTip } from "../../../../../../../ui/message-icon/message-icon.component";
import { ResponseFormContext } from "../../../../response-form.context";
import ResponseOptionPopover from "../../response-option-popover/response-option-popover.component";
import { reqComm, respOption } from "../../../../../../../app.constants";
import { AppContext } from "../../../../../../../app.context";

type RequirementScoreRowProps = {
  comments: string;
  description: string;
  name: string;
  onSave: (key: string, value: string | number) => void;
  responseOptionIndex: number;
};

const useStyles = makeStyles(() => styles);
const ResponseOptionAndCommentRow: React.FC<RequirementScoreRowProps> = ({
  description,
  name,
  comments,
  onSave = () => {},
  responseOptionIndex,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState(false);
  const { showAllComments, showAllDescriptions } = useContext(ResponseFormContext);
  const { isProductResponsesView } = useContext(AppContext);

  useEffect(() => {
    setShowComment(showAllComments);
  }, [showAllComments]);

  const handleSave = (key: string, value: number) => {
    handleEvent(onSave, key, value);
  };

  const handleCommentsButtonClick = () => {
    setShowComment((prev) => !prev);
  };

  const handlePopoverClick = (value: number) => {
    if (value !== responseOptionIndex) {
      handleSave(respOption, defaultToZero(value));
    }
  };

  const showAllCommentsOrHideAllDescriptions = showAllComments || !showAllDescriptions;

  return (
    <Fragment>
      <div className={classNames(classes.topAlligned, classes.top)}>
        <div className={classes.left}>
          <div className={classNames(classes.listItemHeader)}>
            <div className={classes.name}>
              <Paragraph type={"p2"}>{name || <span className="italic">{t("Untitled requirement")}</span>}</Paragraph>
            </div>
          </div>
          <Collapse in={showAllDescriptions}>
            <div className={classes.description}>
              <Paragraph type={"p3"} rootClassName={"gx-survey-response-description-text"}>
                {description}
              </Paragraph>
            </div>
          </Collapse>
        </div>
        <div className={classes.right}>
          <ResponseOptionPopover
            onSelectResponseOption={handlePopoverClick}
            responseOptionIndex={responseOptionIndex}
          />
          <MessageIconWithToolTip
            isOpen={showComment}
            hasComments={!!(comments || comment)}
            handleClick={handleCommentsButtonClick}
          />
        </div>
      </div>
      <Collapse in={showComment}>
        <div className={classNames(classes.bottom, showAllCommentsOrHideAllDescriptions && classes.bottomMargin)}>
          {isProductResponsesView && !!comments && (
            <div className={classes.commentsWrapper}>
              <Paragraph rootClassName={classes.commentsTitle} type={"p4"}>
                {t("Comments")}
              </Paragraph>
              <Paragraph type={"p3"}>{comments}</Paragraph>
            </div>
          )}
          {!isProductResponsesView && (
            <BaseTextArea
              flavor="OUTLINED"
              handleAutoSave={(value: number) => handleSave(reqComm, value)}
              helperText={t("Comments")}
              minRows={1}
              onChange={(e: { target: { value: string } }) => setComment(e?.target?.value)}
              placeholder=" "
              rootClassName="gx-survey-responses-list-item-comments"
              value={defaultToEmptyString(comments)}
            />
          )}
        </div>
      </Collapse>
    </Fragment>
  );
};

export default ResponseOptionAndCommentRow;
