import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./response-list-header.styles";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../app.context";
import {
  Paragraph,
  Header,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
  TypographyComponent,
} from "gx-npm-ui";
import ResponseListControls from "./response-list-controls/response-list-controls.component";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flag";

const useStyles = makeStyles(() => styles);

const ResponsesListHeader = () => {
  const { categories } = useContext(AppContext);
  const [questionsTotal, setQuestionsTotal] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);

  useEffect(() => {
    if (categories.length === 0) {
      return;
    }
    const answeredQuestions = categories.reduce(
      (count, category) => count + category.items.filter((item) => item.responseOptionId !== "").length,
      0
    );
    const totalQuestionsCount = categories.reduce((count, category) => count + category.items.length, 0);

    setAnsweredCount(answeredQuestions);
    setQuestionsTotal(totalQuestionsCount);
  }, [categories]);

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.header}>
      <div className={classes.leftText}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p3"}>{t("Questions answered")}</TypographyComponent>
            <TypographyComponent styling={"h5"}>
              {answeredCount} / {questionsTotal}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph type="p3">{t("Questions answered")}</Paragraph>
            <Header type={"h5"}>
              {answeredCount} / {questionsTotal}
            </Header>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classes.controls}>
        <ResponseListControls />
      </div>
    </div>
  );
};

export default ResponsesListHeader;
