import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InitUserRole, useUserInitAccess } from "gx-npm-lib";
import NoResponsesSvg from "../../assets/images/no-responses.svg";
import InfoSvg from "../../assets/images/info.svg";
import styles from "./no-responses-eval-view.styles";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../app.context";
import {
  Button,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import * as singleSpa from "single-spa";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const useStyles = makeStyles(() => styles);

const NoResponsesEvalView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initId, surveyId } = useContext(AppContext);
  const { hasLoadedAccess, role } = useUserInitAccess(initId);

  const isOwner = hasLoadedAccess && role === InitUserRole.OWNER;
  const headerText = surveyId ? t("This vendor has no responses.") : t("Questionnaire has not yet been set up.");
  return (
    <div className={classes.root}>
      <img src={NoResponsesSvg} alt="no-responses" />
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h3"} rootClassName={classes.headerText}>
            {headerText}
          </TypographyComponent>
          <TypographyComponent styling={"p3"} rootClassName={`p3 ${classes.subHeaderText}`}>
            {t("Questionnaire responses for this vendor will")}
            <br />
            {t("appear when they share responses to an active questionnaire.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <h3 className={classes.headerText}>{headerText}</h3>
          <p className={`p3 ${classes.subHeaderText}`}>
            {t("Questionnaire responses for this vendor will")}
            <br />
            {t("appear when they share responses to an active questionnaire.")}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {isOwner && (
        <div className={classes.navButton}>
          <Button
            onClick={() => singleSpa.navigateToUrl(`/s/evaluation/${initId}/questionnaire`)}
            rootClassName="btn-tertiary"
          >
            {t("GO TO QUESTIONNAIRE TAB")}
          </Button>
        </div>
      )}
      <div className={classes.infoIcon}>
        <span className={classes.infoVector} />
        <img src={InfoSvg} alt="info" />
        <span className={classes.infoVector} />
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"p4"} rootClassName={`p4 ${classes.infoText}`}>
            {t("Vendor responses in BuySmart are not reviewed by Gartner")}
            <br />
            {t("Experts and do not necessarily represent the views or")}
            <br />
            {t("opinions of Gartner.")}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={`p4 ${classes.infoText}`}>
            {t("Vendor responses in BuySmart are not reviewed by Gartner")}
            <br />
            {t("Experts and do not necessarily represent the views or")}
            <br />
            {t("opinions of Gartner.")}
          </p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default NoResponsesEvalView;
