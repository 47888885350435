import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { PopoverMenu, Paragraph, useFeatureFlag } from "gx-npm-ui";
import styles from "./response-option-popover.styles";
import { AppContext } from "../../../../../../app.context";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../../../lib/feature-flag";

const useStyles = makeStyles(() => styles);
type ScoringPopoverProps = {
  responseOptionIndex: number;
  onSelectResponseOption: (responseOptionIndex: number) => void;
  rootClassName?: string;
};

const ResponseOptionPopover: React.FC<ScoringPopoverProps> = ({
  onSelectResponseOption = () => {},
  rootClassName = "",
  responseOptionIndex = -1,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = (value: number) => {
    if (value !== responseOptionIndex) {
      onSelectResponseOption(value);
    }
  };

  const { options, isProductResponsesView } = useContext(AppContext);
  const menuItems = options.map((item, index) => ({
    ...item,
    index: index,
  }));

  return (
    <div className={classNames(rootClassName, classes.popoverWrapper, isFFGCOM3695 && classes.popoverWrapperGCOM3695)}>
      {responseOptionIndex === -1 && isProductResponsesView && (
        <Paragraph rootClassName={classes.noSelectionViewOnly} type={"p2"}>
          {t("No response")}
        </Paragraph>
      )}
      {responseOptionIndex !== -1 && isProductResponsesView && (
        <Paragraph boldness={"medium"} rootClassName={classes.selectedViewOnly} type={"p2"}>
          {menuItems[responseOptionIndex].name}
        </Paragraph>
      )}
      {!isProductResponsesView && (
        <PopoverMenu
          noCurrentSelectionText={t("Please select")}
          isBehindAppBar={false}
          isClosedOnScroll={true}
          menuItems={menuItems}
          onClick={handleClick}
          rootClassName="gx-survey-responses-scoring-popover"
          selectedIndex={responseOptionIndex}
          isDynamicWidth={false}
        />
      )}
    </div>
  );
};
export default ResponseOptionPopover;
