import { colorPalette, weightPalette } from "gx-npm-common-styles";

const responseOptionPopoverStyles = {
  selectedViewOnly: {
    color: colorPalette.neutrals.carbon.hex,
    marginRight: 67,
  },
  noSelectionViewOnly: {
    color: colorPalette.neutrals.iron.hex,
    marginRight: 67,
  },
  popoverWrapper: {
    display: "flex",
    "& .gx-survey-responses-scoring-popover": {
      width: 600,
      "@media (max-width:1440px)": {
        width: 460,
      },
      "@media (max-width:1240px)": {
        width: 380,
      },
      padding: 12,
      border: `1px solid ${colorPalette.neutrals.stone.hex}`,
      marginLeft: 76,
      marginRight: 20,
      "&.open": {
        border: `2px solid ${colorPalette.interactions.blueBerry.hex}`,
        padding: 11,
      },
      "& p": {
        color: colorPalette.neutrals.iron.hex,
        fontSize: "16px",
        fontWeight: weightPalette.regular.amount,
        fontVariationSettings: weightPalette.regular.variation,
        "&.gx-popover-menu-current-selection": {
          color: colorPalette.neutrals.carbon.hex,
          fontWeight: weightPalette.medium.amount,
          fontVariationSettings: weightPalette.medium.variation,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      },
      "& .gx-popover-menu-selected": {
        width: "98%",
        "@media (max-width:1440px)": {
          width: "97%",
        },
        "@media (max-width:1240px)": {
          width: "96%",
        },
        "&.carbon": {
          "& p": {
            color: colorPalette.neutrals.carbon.hex,
          },
        },
      },
      "& .gx-popover-menu-list": {
        left: -2,
        top: 49,
        width: "100%",
        border: `2px solid ${colorPalette.neutrals.silver.hex}`,
        "& a": {
          textWrap: "balance",
          wordWrap: "break-word",
        },
      },
    },
    "& .tool-tip": {
      display: "flex",
      width: "unset",
    },
  },
  popoverWrapperGCOM3695: {
    "& .gx-survey-responses-scoring-popover": {
      "&.open": {
        border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
      },
    },
  },
};

export default responseOptionPopoverStyles;
